<template>
  <div />
</template>

<script>
export default {
  created() {
    const query = this.$route.query;
    this.$router.replace({ name: "VideoPlay", query });
  },
};
</script>

<style lang="scss" scoped>
</style>
